@import "src/index.scss";




.quote-section{
  position: relative;
  width: 100%;
  background: $healthLightPink;
  padding: 3rem 20px;
  text-align: center;
  max-width:$maxWidth;
  margin: 3rem auto;

  p{
    font-size: 1rem;
    margin-bottom: 0;
    font-style: italic;

    &:first-child{
      font-weight: 700;
      font-size: 1.5rem;
      font-style: normal;
      width: 100%;
      max-width: 740px;
      /* text-align: center; */

      margin: 0 auto;
      padding: 0 20px;
      line-height: 32px;

      @include break-point('at least', 'medium') {
        font-size: 2rem;
        line-height: 40px;
      }

    }

  
  
  }
  
  button {
    @include button();
    display: inline-block;
    border-radius: 0;
    background-color: $color__orange;
    flex-basis: 40%;
    margin: 10px 0 0 2px;
    font-size: 13px;
  
    padding: 15px 50px;
  
    @include break-point('at least', 'medium') {
    font-size: 17px;
    flex-basis: 25%;
    }
  
    &:hover {
    background-color: $color__highlight;
    }
  }
  }

