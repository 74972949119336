@import "src/index.scss";

.services {

  .sub-title {
    @include fonts__sans-serif;
    font-size: 17px;
    text-transform: uppercase;
    color: $healthPink;
    font-weight: 700;
  }

  h2,
  h3 {
    margin: 2.5rem 0 1rem;
  }

  h2 {
    font-size: 1.7rem;
    margin: 2.5rem 0 1rem;

    @include break-point("at least", "medium") {
      font-size: 2rem;
    }
  }


  p {
    @include fonts__sans-serif;
    font-size: 16px;
    margin: 0 0 10px;

    @include break-point("at least", "medium") {
      font-size: 1.1rem;
    }
  }



  // img {
  //   margin: 2.5rem 0 0;
  //   width: 25px;
  // }

  .skill-list-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .skill-list {
      // background-color: #ED1E451A;
      width: 100%;
      padding: 0;
      margin-bottom: 20px;

      @include break-point("at least", "medium") {
        padding: 0;
      }


      ul {
        list-style: none;
        padding: 0;
        margin-left: 20px;

        li {
          @include fonts__sans-serif;
          font-size: 16px;
          text-indent: -10px;
          line-height: 26px;

          @include break-point("at least", "medium") {
            font-size: 1.1rem;
          }

          &::before {
            content: "●";
            color: $healthPink;
            /* Set the color to red */
            margin-right: 8px;
            display: inline-block;
          }
        }
      }
    }
  }


  .box-items {
    display: flex;
    flex-wrap: wrap;
    margin: 3rem 0 0;

    .box {
      width: 100%;
      margin-bottom: 2rem;

      @include break-point("at least", "medium") {
        width: 50%;
      }


      &:nth-child(odd) .content {
        margin-right: 0;
      }

      &:nth-child(even) .content {
        margin-left: 0;
      }



      @include break-point("at least", "medium") {

        &:nth-child(odd) .content {
          margin-right: 20px;
        }

        &:nth-child(even) .content {
          margin-left: 20px;
        }

      }



      .content {

        .img-cont {

          position: relative;

          .img {
            width: 100%;
            border-radius: 6px;
            border: 1px solid #e9e9e9;
          }

          .icon {
            position: absolute;
            bottom: 15px;
            left: 15px;
            width: 45px;
          }
        }

        h3 {
          font-size: 24px;
          padding: 0;
          margin: 1rem 0;


          @include break-point("at least", "medium") {
            padding: 0 20px;
          }
        }


        p {
          padding: 0;
          line-height: 28px;

          @include break-point("at least", "medium") {
            padding: 0 20px;
          }
        }
      }
    }
  }

}

.testimonials-cont {
  background-color: #f4f4f5;

  margin: 0 auto !important;

  h3{
    text-align: center;
    padding: 20px 0;


    @include break-point("at least", "medium") {
      padding: 20px 0 0 0;

    }
  }

  .testimonials {

    display: flex;
    justify-content: center;
    flex-direction: column;


    @include break-point("at least", "medium") {

      flex-direction: row;

    }

    .box {
      background: #ffffff;
      padding: 20px;
      width: 100%;
      max-width: 100%;
      margin: 0 0 20px;
      border-radius: 4px;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);

      @include break-point("at least", "medium") {
        width: 50%;
      max-width: 400px;
      padding: 30px;
      margin: 30px;

      }

      p{
        font-size: 15px;
        line-height: 24px;
        margin: 0;
        @include fonts__sans-serif;
      }

      .details {
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 10px;
      }
      .name, .role{
        font-style: italic;
        color: #7d7d7d;
      }
    }
  }
}