@import "src/index.scss";



.phone {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @include break-point('at least', 'medium') {
    justify-content: flex-end;
    width: auto;
  }



  .phone-sub {
    display: flex;
    height: 100%;
    align-items: center;

    @include break-point('at least', 'medium') {
      height: 40px;
    }

    @media screen and (min-width: 768px) and (max-width: 800px) {

      display: none;
    }

    img {
      // width: 28px;
      margin-right: 6px;
      height: 30px;

      @include break-point('at least', 'medium') {
        height: 40px;
      }
    }

    .number {
      display: flex;
      flex-direction: column;
      padding: 0 10px 0 0;
      justify-content: center;

      p {
        @include fonts__sans-serif;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 300;
        letter-spacing: 1px;
        line-height: 15px;
        margin: 0;
        padding: 0;
      }
    }
  }

  .make-appt {
    display: flex;
    background-color: $healthPink;
    align-items: center;
    padding: 0 10px;
    height: 70px;

    p {
      @include fonts__sans-serif;
      font-size: 11px;
      // text-transform: uppercase;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 15px;
      margin: 0;
      color: #fff;
      text-align: center;
    }
  }
}
