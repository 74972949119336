@import "src/index.scss";



.contact-form{
    display: flex;
    margin: 0 auto;
    max-width: 810px;
    flex-wrap: wrap;
    background: #E4E4E766;
    padding: 10px;

    .msg{
        margin-bottom: 20px;
    }

    #errorDiv{
        @include fonts__sans-serif;
        color: #c50707;
        font-size: 12px;
        width: 100%;
    }
    
    @include break-point('at least', 'medium') {
        padding: 20px 30px;
    }

    .error{
        @include fonts__sans-serif;
        color: #c50707;
       // font-style: italic;

       font-size: 12px;
       font-weight: 700;
       display: block;
       margin: 10px 0 0;
       min-height: 12px;

    }

    @include text-input(); 


    h3{
        flex-basis: 100%;
        margin-bottom: 10px;
    }

    input{
       // border-color: $color__orange !important;
        margin: 0;
        border-radius: 0 !important;
        flex-basis: 100%;
        margin: 2px;
        border: none !important;
        background: none !important;
        border-bottom: 1px solid #d0d0d0 !important;
        @include break-point('at least', 'medium') {
            // flex-basis: 75%;
        }
        outline: none !important;
        outline-width: 0 !important;
    }

    .message, .subject{
        flex-basis: 100%;
        border: none;
    }
    .name, .email{
        flex-basis: 100%;
        border: none;

        @include break-point('at least', 'medium') {
            // flex-basis: 49.5%;
        }

    }



    textarea{
        //border-color: $color__orange !important;
        border-radius: 0 !important;
        margin: 2px;
        padding: 15px;
        font-size: 1rem;
        @include fonts__sans-serif;
        background: #f4f4f5;
        outline: none !important;
        border-bottom: 1px solid #d0d0d0 !important;
        outline-width: 0 !important;
    }
    
    button{
        @include button();
        display: inline-block;
        border-radius: 0;
        background-color: $healthPink;
        flex-basis: 43%;
        margin: 10px 0 0 2px;
        border-radius: 4px;
        text-transform: none;
        font-weight: 400;
        font-size: 14px;
        padding: 13px 23px;

        @include break-point('at least', 'medium') {
            flex-basis: 35%;
        }
        &:hover{
            background-color: $healthPinkOver;
        }
    }

    .btn-inactive{
        pointer-events: none;
        opacity: 0.2;
    }
    .btn-active{
        pointer-events: auto;
        opacity: 1;
    }

    .sent{
        @include fonts__sans-serif;
        display: block;
        padding: 21px 27px;
        font-size: 13px;
        height: 25px;
        margin: 10px 0 0 2px;
        color: #f16334;
    }

 }


 .reply-form{
    display: flex;
    margin: 0 auto;
    max-width: 1024px;

 }

 .blink_me {
    animation: blinker 0.6s 2 linear;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }



  #spinner{
	display: inline-block;
	position: relative;
	width: 10px;
	height: 10px;
	margin: 0 0 3px 8px;


	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 17px;
		height: 17px;
		margin: 0px;
		border: 3px solid #423535;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: #fff transparent transparent transparent;
		&:nth-child(1) {
			animation-delay: -0.45s;
		}
		&:nth-child(2) {
			animation-delay: -0.3s;
		}
		&:nth-child(3) {
			animation-delay: -0.15s;
		}
	}


}


@keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }



.ring-white {
	div {
		border: 3px solid rgb(62, 62, 62);
		border-color: rgb(62, 62, 62) transparent transparent transparent;
	}
}
.ring-black {
	div {
		border: 3px solid #423535;
		border-color: #423535 transparent transparent transparent;
	}
}

.ring-blue {
	div {
	border: 3px solid rgb(202, 202, 202);
	border-color: rgb(215, 215, 215) transparent transparent transparent;
  }
}