@import "src/index.scss";

.aboutus {

  .sub-title {
    @include fonts__sans-serif;
    font-size: 17px;
    text-transform: uppercase;
    color: $healthPink;
    font-weight: 700;
  }

  img{
    margin: 2.5rem 0 0;
    width: 25px;
  }


  .pink-list-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .pink-list {
      background-color: $lightBlueListBlock;
      width: 100%;
      padding: 1rem;
      margin-bottom: 20px;
      @include break-point("at least", "medium") {
        width: 49%;
        padding: 2rem;
      }


      div {
        font-size: 20px;
        font-weight: 700;
        @include fonts__sans-serif;

        span {

          vertical-align: sub;

          img {
            margin-right: 10px;
            margin-top: 0;
          }
        }
      }

      ul {
        list-style: none;
        padding: 0;
        margin-left: 20px;
        margin-left: 20px;

        li {
          @include fonts__sans-serif;
          font-size: 15px;
          text-indent: -10px;

          &::before {
            content: "\25BA";
            color: $healthPink;
            /* Set the color to red */
            margin-right: 8px;
            transform: scaleY(1.5);
            display: inline-block;
            font-size: 10px;
          }
        }
      }
    }
  }

}

