@import "src/index.scss";

.paragraph {
    h1,
    h2,
    h3 {
        margin: 1rem 0 1.5rem;
    }


    h2 {
    
        // @include break-point("at least", "medium") {
        //     font-size: 2rem;
        // }
    }




    p {
        @include fonts__sans-serif;
        font-size: 16px;
        margin: 0 0 18px;

        @include break-point("at least", "medium") {
            font-size: 1.1rem;
        }

        a{
            color:#1329b4;
        }
    }
}