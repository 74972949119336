@import "src/index.scss";

.disclaimers {
  position: relative;
  top: 70px;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  .head {
    position: relative;
    height: 160px;
    background: $healthPink;

    display: flex;
    align-items: center;

    h2{
      color:#fff;
      position: relative;
      left: 80px;
    }
  }

  .content {

    max-width: 950px;
    margin: 1.5rem auto 3rem;

    padding: 10px;

    p {
      @include fonts__sans-serif;
      font-size: 16px;
      margin: 0 0 10px 0;

      @include break-point("at least", "medium") {
        font-size: 1.1rem;
      }
    }
  }


}