@import "src/index.scss";

//
// Mobile
// ------------------------------------------------------------------------

$color__footer: #f0f0f0;



.footer-container {
  background: #0330df; //$bgColorB;
  margin-top: 3rem;

  .footer {

    max-width: 1180px;
    margin: 0 auto;

    padding: 0 20px 3rem;


    &__header {
      padding: 8px 0;
      text-align: center;
      // border-bottom: 1px solid #cccccc;
      flex-direction: column;
      display: flex;
      justify-content: center;
      .left-s {
        width: 100%;
        padding-right: 30px;

        img {
          max-width: 150px;
          width: 100%;
        }
      }

      .right-s {
        width: 100%;

        hr {
          border: none;
          border-top: 1px solid #ffffff1f;
        }

        .acknowledgement-box {

          margin: 3rem 0 1rem;

          h3 {
            font-size: 16px;
            margin-bottom: 10px;
            color: #dcdcdc;
            ;
            text-align: left
          }

          p {
            @include fonts__sans-serif;
            color: #dcdcdc;
            ;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
          }
        }

        .box-b {

          display: flex;
          justify-content: space-between;

          flex-direction: column;

          .copyright {
            // @include fonts__sans-serif;
            color: rgb(90, 90, 90);
            padding: 5px 0 16px 0;
            font-size: 11px;

            text-align: center;

            &__text {
              @include fonts__sans-serif;

              font-size: 11px;
              // font-size: 0.8rem;

              margin-bottom: 16px;

              width: 100%;
              display: inline-block;

              text-align: left;
              color: #dcdcdc;

              a {
                color: #dcdcdc;

                &:hover {
                  color: #a7a7a7;
                }
              }
            }

            &__logo {
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              width: 100%;
              height: 36px;
              display: inline-block;
            }
          }

          .social{

          }
        }

      }


    }
  }
}



@include break-point('at least', 'medium') {
.footer-container {
  background: #0330df; //$bgColorB;
  margin-top: 3rem;

  .footer {

    max-width: 1180px;
    margin: 0 auto;

    padding: 0 20px;


    &__header {
      padding: 8px 0;
      text-align: center;
      // border-bottom: 1px solid #cccccc;
      flex-direction: row;
      display: flex;
      justify-content: center;


      .left-s {
        width: 30%;
        padding-right: 30px;

        img {
          max-width: 250px;
        }

      }

      .right-s {
        width: 70%;



        .box-b {
          flex-direction: row;
        }

      }
      

    }
  }
}
}