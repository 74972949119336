@import "src/index.scss";

.partnerships {

  .sub-title {
    @include fonts__sans-serif;
    font-size: 17px;
    text-transform: uppercase;
    color: $healthPink;
    font-weight: 700;
  }

  h2,
  h3 {
    margin: 2.5rem 0 1rem;
  }

  h2{
    margin: 2.5rem 0 1rem;
    font-size: 1.7rem;
    @include break-point("at least", "medium") {
      font-size: 2rem;
    }
  }

  h3{
    font-size: 1.4rem;
    @include break-point("at least", "medium") {
      font-size: 1.8rem;
    }
  }



  p{
    @include fonts__sans-serif;
    font-size: 16px;
    margin: 0 0 10px;

    @include break-point("at least", "medium") {
      font-size: 1.1rem;
    }
  }



  img{
    margin: 2.5rem 0 0;
    width: 25px;
  }

  .skill-list-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .skill-list {
      // background-color: #ED1E451A;
      width: 100%;
      padding: 0;
      margin-bottom: 20px;
      @include break-point("at least", "medium") {
        padding: 0;
      }


      ul {
        list-style: none;
        padding: 0;
        margin-left: 20px;

        li {
          @include fonts__sans-serif;
          font-size: 16px;
          text-indent: -10px; 

          @include break-point("at least", "medium") {
            font-size: 1.1rem;
          }

          &::before {
            content: "●";
            color: $healthPink;
            /* Set the color to red */
            margin-right: 8px;
            display: inline-block;
          }
        }
      }
    }
  }

}

