@import "src/index.scss";

.contact-us{

  display: flex;
  // flex-direction: column;
  
  @media screen and (max-width: 900px) {
    flex-direction: column;
  };

  .left, .right{
    width: 50%;

    margin: 0 0 3rem;

    @include break-point("at least", "medium") {
      margin: 3rem 0 3rem;
    }
      
    @media screen and (max-width: 900px) {
      width: 100%;
    };


    .heart{
      width: 28px;
      margin-left: 30px;
    }

    // p{
    //   @include fonts__sans-serif;
    //   font-size: 15px;
    //   margin: 0 0 0 30px;
    // }


    p{
      @include fonts__sans-serif;
      font-size: 15px;
      margin: 0 0 0 30px;

      @include break-point("at least", "medium") {
        font-size: 17px;
      }
    }


    h2{
      margin: 0 0 25px 30px;
      font-size: 1.7rem;

      @include break-point("at least", "medium") {
        font-size: 2rem;
      }
    }
    .c-group{
      display: flex;
      img{
        width: 13px;
        margin-right: 17px;
      }
      p{
        margin: 0;
      }
    }
  }

  .left{
    margin: 1rem 0 3rem;

    @include break-point("at least", "medium") {
      margin: 3rem 0 3rem;
    }
  }

  .right{
    p{
      margin:0;
    }
    .privacy{
      margin-top: 30px;
      p{
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 5px;
        color: #22222299;
        &:first-child{
          color: #222222;
          font-weight: 700;
        }
      }
    }
  }

  .reply{
    width: 100%;
  }

}

.cf-social{
  vertical-align: middle;
  width: 18px;
  margin-left: 10px;


}

// @include break-point("at least", "small") {
//   .not-found-page {
//     &__tag {
//       line-height: 2;
//       font-size: 1.75rem;
//     }

//     h1 {
//       font-size: 2.5rem;
//       line-height: 1.125;
//       margin-bottom: 24px;
//     }
//   }
// }
