@import "src/index.scss";


.header-solid {
  height: 34vh;
  position: relative;
  margin: 70px auto 0;

  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // z-index: -1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // background-color: #444444;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: 30% 0px;
  }

  .holder {
    width: 100%;
    height: 100%;

    .hero-content {
      height: 100%;
      width: 100%;
      max-width: 1120px;
      align-items: center;
      text-align: center;
      justify-content: center;
      margin: 0 auto;
      /* padding-top: 16vh; */
      /* padding-bottom: 12vh; */
      position: relative;
      display: flex;
      // flex-direction: column-reverse;

      @include break-point('at least', 'medium') {
        flex-direction: row;
      }

      .left-s {
        width: 100%;
        height: 100%;
        // background-color: $healthPink; //#0330df
        display: flex;
        // justify-content: center;

        @include break-point('at least', 'medium') {
          // width: 40%;
          // justify-content: center;
          // justify-content: flex-end;
        }

        div {
          // width: 100%;
          // display: flex;
        }

        .left-s-A {
          width: 1px;

          @include break-point('at least', 'medium') {
            width: 60%;
          }
        }

        .details {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 0;

          // @include break-point('at least', 'medium') {
          //   padding-left: 40%;
          // }


          // @include break-point('at least', 'medium') {
          //   justify-content: flex-end;
          // }

          .site-map {
            @include fonts__sans-serif;
            font-size: 13px;
            text-align: left;
            color: #ffffff;
          }

          .sub-details {
            display: flex;
            flex-direction: column;

            .cont {
              width: 100%;
              display: flex;
              flex-direction: column;
              width: 100%;
              padding: 0 20px;


              @include break-point('at least', 'medium') {
                width: 420px;
                padding: 0;
                padding: 0 20px;
              }
            }

            img {
              width: 25px;
            }

            h2,
            p {
              color: #ffffff;
              text-align: left;

            }

          }
        }
      }

    }

    .right-s {
      width: 100%;
      height: 100%;
      // background-color: $healthPink;
      background-size: cover;
      background-repeat: no-repeat;

      display: none;

      @include break-point('at least', 'medium') {
        width: 60%;
        display: block;
      }
    }
  }


  /* UI Properties */

  // background: transparent linear-gradient(90deg, var(--unnamed-color-ed1e45) 0%, #F05371 46%, #FBFBFB 100%) 0% 0% no-repeat padding-box;
  // background: transparent linear-gradient(90deg, #ED1E45 0%, #F05371 46%, #FBFBFB 100%) 0% 0% no-repeat padding-box;
  // mix-blend-mode: multiply;
  opacity: 1;

  .dd-details {

    max-width: 640px;
    padding: 10px;

    @include break-point('at least', 'medium') {
      padding: 0;
    }

    p {
      // @include fonts__sans-serif;
      color: #e0e0e0;
      font-size: 20px;
      line-height: 26px;

      @include break-point('at least', 'medium') {
        font-size: 22px;
        line-height: 30px;
      }
    }

    h1 {
      @include fonts__sans-serif;
      color: #e0e0e0;
      font-size: 40px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 20px;

      @include break-point('at least', 'medium') {
        font-size: 70px;
      }

    }

  }

}




.header-gradient {
  height: 35vh;
  position: relative;
  margin: 70px auto 0;
  display: flex;
  flex-direction: column;


  @include break-point('at least', 'medium') {
    height: 70vh;
  }



  .content {
    // position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // z-index: -1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // background-color: #444444;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    // background-position: 30% 0px;
  }

  .pink-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(90deg, var(--unnamed-color-ed1e45) 0%, #F05371 46%, #FBFBFB 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(90deg, #ED1E45 0%, #F05371 46%, #FBFBFB 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    opacity: 1;

  }

  .hero-content {
    height: 100%;
    width: 100%;
    // align-items: center;
    text-align: center;
    justify-content: center;
    /* padding-top: 16vh; */
    /* padding-bottom: 12vh; */
    position: relative;
    display: flex;
    // flex-direction: column-reverse;

    @include break-point('at least', 'medium') {
      flex-direction: row;
    }

    /* UI Properties */

    // background: transparent linear-gradient(90deg, var(--unnamed-color-ed1e45) 0%, #F05371 46%, #FBFBFB 100%) 0% 0% no-repeat padding-box;
    // background: transparent linear-gradient(90deg, #ED1E45 0%, #F05371 46%, #FBFBFB 100%) 0% 0% no-repeat padding-box;
    // mix-blend-mode: multiply;
    // opacity: 1;


    .left-s {
      width: 100%;
      height: 100%;
      // background-color: $healthPink;
      display: flex;

      @include break-point('at least', 'medium') {
        width: 50%;
      }

      div {
        width: 100%;
        display: flex;
      }

      .left-s-A {
        width: 20px;

        @include break-point('at least', 'medium') {
          width: 36%;
        }
      }

      .details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        .sub-details {
          display: flex;
          flex-direction: column;

          .welcome {
            @include fonts__sans-serif;
            font-size: 19px;
            text-align: left;
            color: #ffffff;
            margin: 0;
          }

          h2,
          p {
            color: #ffffff;
            text-align: left;
            font-weight: 400;

          }

          h2 {
            font-size: 29px;
            // letter-spacing: 2px;
            font-weight: 700;
              max-width: 408px;
            
            @include break-point('at least', 'medium') {
              font-size: 40px;
            }
          }

          .h2-bold {
            font-weight: 700;
            font-size: 2.6rem;
          }

          .header-q {
            font-size: 26px;
        }

          .btn-more {
            @include fonts__sans-serif;
            font-size: 13px;
            border-radius: 20px;
            background: $healthPink;
            padding: 8px 16px;
            color: #ffffff;
            border: 1px solid #404fcb;
            max-width: 144px;
            margin-top: 2rem;
            cursor: pointer;
            text-decoration: none;

            &:hover {
              background: $healthPinkOver;
              color: #ffffff;
            }

            @media only screen and (orientation: portrait) {
              color: #404fcb;
              background: #ffffff;
              font-weight: 700;
              border: 1px solid #ffffff;
            }
          }


          .smarter {
            @include fonts__sans-serif;
            font-size: 13px;
            text-align: left;
            color: #ffffff;
            margin: 0;
            font-weight: 300;
          }

        }
      }
    }

    .right-s {
      width: 100%;

      display: none;

      // background-image: url(./images/health/aboutus-header.jpeg);
      @include break-point('at least', 'medium') {
        width: 50%;
        display: block;
      }

    }

  }

}



.header-h2 {
  font-size: 2em !important;

  // margin-right: 15px;
  @include break-point('at least', 'medium') {
    font-size: 35px !important;
  }
}

.phone {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;

  @include break-point('at least', 'medium') {
    justify-content: flex-end;
    width: auto;

  }
}