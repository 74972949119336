//@import url('http://localhost:10008/wp-content/themes/twentytwentyone/style.css');
//@import url('http://localhost:10008/wp-includes/css/dist/block-library/style.min.css?ver=5.9.3');
//@import url('https://lamainteractives.com/cms/wp-content/themes/twentytwentytwo/style.css');


/** Import custom styles **/
@import "./site-styles/styles/";
//@include reset();
// @include fonts(default);
@include standard-typography();




$healthPink : #0330df;
$healthPinkOver : #264eee;
$healthLightPink: #edefff;
$bgColor: #fafafa;
$bgColorB: #fbfbfb;
$brandBlack: #222222;
$brandBlackHover:$healthPink;
$maxWidth: 1120px;

$lightBlueListBlock: #edefff;

$titleFontColor: #1a30df;
$regFontColor: #0a1633;



/**

-------------
Wordpress styles overrides 

*/



.wp-block-separator:not(.is-style-wide):not(.is-style-dots) {
    width: 100px;
}
// .wp-block-separator.has-background:not(.is-style-wide):not(.is-style-dots) {
//     height: 2px;
// }


:root{
    --global--color-green: white;
}


.wp-block-preformatted{
	border: 1px solid #d1d1d1;
    padding: 10px;
    border-radius: 6px;
}

.wp-block-quote{
    padding: 20px;
    text-align: right;
    margin-bottom: 1.5rem;
    p{
        //@include fonts__serif-heading;
        @include fonts__sans-serif;
        font-size: 1.6rem;
        font-style: italic;
        text-align: left;
        line-height: 2rem;
        color: #4c4c4c;
        margin: 0;
    }
    cite{
        @include fonts__sans-serif;
        font-size: 11px;
        font-style: initial;
        text-transform: uppercase;
        
    }
}

