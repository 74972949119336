@import "src/index.scss";


.home-gs_bg,
.ngs {
  height: 50vh;
  position: relative;
  margin: 46px auto 0;

  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // z-index: -1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // background-color: #444444;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: 30% 0px;
  }

  .hero-content {
    height: 100%;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    /* padding-top: 16vh; */
    /* padding-bottom: 12vh; */
    position: relative;
    display: flex;
    flex-direction: column;

    /* UI Properties */

    background: transparent linear-gradient(90deg, var(--unnamed-color-ed1e45) 0%, #F05371 46%, #FBFBFB 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(90deg, #ED1E45 0%, #F05371 46%, #FBFBFB 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    opacity: 1;



    .details {

      max-width: 640px;
      padding: 10px;

      @include break-point('at least', 'medium') {
        padding: 0;
      }

      //   p {
      //     // @include fonts__sans-serif;
      //     color: #e0e0e0;
      //     font-size: 20px;
      //     line-height: 26px;

      //     @include break-point('at least', 'medium') {
      //       font-size: 22px;
      //       line-height: 30px;
      //     }
      //   }

      p {
        @include fonts__sans-serif;
        font-size: 16px;
        margin: 0;

        @include break-point("at least", "medium") {
          font-size: 1.1rem;
        }
      }


      h1 {
        @include fonts__sans-serif;
        color: #e0e0e0;
        font-size: 40px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 20px;

        @include break-point('at least', 'medium') {
          font-size: 70px;
        }

      }

    }
  }


}

.home-page {
  margin-bottom: 70px;

  .home-detail {
    margin-top: 150px;
    padding: 0 10px;

    @media screen and (max-width: 1100px) {
      margin-top: 70px;
    }
  }
}


.tabs-holder {
  width: 100%;
  position: absolute;
  z-index: 9;
  top: -230px;
  left: 0;

  @media screen and (max-width: 1100px) {
    position: relative;
    top: 30px;
  }

  .tabs-container {

    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;


    @media screen and (max-width: 1100px) {
      position: relative;
      flex-wrap: wrap;
    }

    .tabs {

      @include fonts__sans-serif;
      text-decoration: none;
      display: block;
      width: 100%;
      max-width: 235px;
      // height: 160px;
      background-color: #efeff1;
      border-radius: 8px;
      margin: 0 5px;
      padding: 20px;

      @media screen and (max-width: 1100px) {
        max-width: 47%;
        margin: 5px;
      }

      p{
        @include fonts__sans-serif;
      }

      &:hover {
        background-color: #e4e4e4;
      }

      .icon {
        width: 35px;
      }

      .title {
        font-weight: 700;
        margin: 0;
      }

      .details {
        font-size: 14px;
        line-height: 17px;
        padding: 10px 0 0;
      }

      .arrow {
        display: flex;
        justify-content: flex-end;

        @media screen and (max-width: 1100px) {
          display: none;
        }
      }
    }
  }
}