@import "src/index.scss";

* {
	padding: 0;
	margin: 0;
}


body {
	//background-color: LavenderBlush;
	@include fonts__sans-serif;
	font-size: 100%;
	line-height: 1.5;
	margin: 0;
	color: #3d3d3d;
	background-color: #fafafa;

	iframe{
		pointer-events: none;
	}

}


/* Define the scrollbar track */
// ::-webkit-scrollbar {
// 	width: 12px; /* Width of the scrollbar */
//   }
  
//   /* Define the scrollbar thumb (the draggable part) */
//   ::-webkit-scrollbar-thumb {
// 	background-color: #d8d8d8; /* Change this to your desired color */
// 	border-radius: 4px; /* Rounded corners for the thumb */
//   }
  
//   /* Define the scrollbar track when not hovered */
//   ::-webkit-scrollbar-track {
// 	background-color: #f1f1f1; /* Change this to your desired color */
// 	border-bottom-right-radius: 4px;
// 	border-top-right-radius: 4px;
//   }
  
//   /* Define the scrollbar thumb when hovered */
//   ::-webkit-scrollbar-thumb:hover {
// 	background-color: #f16334; /* Change this to your desired color on hover */
//   }




*,
::after,
::before {
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
	//@include fonts__serif-heading;
	@include fonts__sans-serif;
}

p {
	//@include fonts__serif-heading;
	@include fonts__sans-serif;
}




a {
	color: $color__orange;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}

figure{
	margin-bottom: 1rem;
}



// .horizontal-divider {
// 	display: inline-block;
// 	border-top: 2px solid $color__orange;
// 	width: 100px;
// 	height: 2px;
// }

ol, ul{
	padding-left: calc(2 * 10px);
    margin-bottom: 1.5rem;
	list-style-type: disc;
	li{
		@include fonts__serif;
		display: list-item;
		font-size: 18px;
		font-size: 1.125rem;
		line-height: 1.7777777778;
	}
}
 
hr{
	margin:1rem 0
}

.preloader{
	width: 100%;
	height: 100vh;
	background-color: green;
}

// .header{
//   width: 100%;
//   max-width: 1600px;
// }

section{
  width: 100%;
  margin: 0 auto;
  max-width: 1600px;
}


.sub-section{
	max-width: $maxWidth;
  margin: 46px auto 0;
	padding: 0 20px;

//   margin-top: 20px;

  @include break-point('at least', 'medium') {
    // margin-top: 0;
  }

}


.section-container {
	width: 100%;
  position: relative;
	.section-header {
		position: relative;
		width: 100%;
		min-height: 370px;
		background-color: rgb(161, 161, 161);
		background-position: center center;
		background-size: cover;

		@include break-point('at least', 'medium') {
			min-height: 460px;
		}

		&__overlay {
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			position: absolute;
			//background-color: #f4f4f4;
			background-color: rgb(0, 0, 0);
			opacity: 0.3;
		}

		.header-contents {
			min-height: 200px;
			padding: 20px;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			//position: absolute;
			z-index: 2;

			@include break-point('at least', 'medium') {
				min-height: 460px;
			}

			&__holder {
				// @include fonts__serif-heading;
				width: 100%;
				max-width: 620px;
				//padding: 10px;
				margin: 0 auto;
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				@include break-point('at least', 'medium') {
					padding: 0;
				}

				h1,
				h2,
				h5 {
					margin: 15px 0;
					padding: 0;
				}

				// p {
				// 	@include fonts__sans-serif;
				// 	padding: 0;
				// 	margin: 0;

				// }

			}
		}
	}


	.section-wrapper {

		width: 100%;
		max-width: 1180px;
		margin: 0 auto;
		padding: 1rem;
		margin: 1.5rem auto;

		.section-title {
			@include fonts__sans-serif;
			font-weight: 700;
			font-size: 1.8rem;

			&:before {
				content: '';
				border-top: 2px solid $color__orange;
				display: block;
				width: 120px;
			}
		}

		.content {
			display: flex;
			flex-direction: column;

			@include break-point('at least', 'medium') {
				flex-direction: row;
			}

			.header {
				min-height: 320px;
				background-color: pink;
			}

			&__main {

				width: 100%;
				padding-right: 0;

				@include break-point('at least', 'medium') {
					width: 66.6%;
					padding-right: 24px;
				}

				.item {
					width: 100%;
					padding: 2em 0;


					.item-details {
						display: flex;
						justify-content: space-between;
					}

					// .date {
					// 	// @include fonts__sans-serif;
					// 	font-size: 14px;
					// }

					h2 {
						@include fonts__sans-serif;

						// @include fonts__serif-heading;
						// font-size: 1.4rem;
						// line-height: 1.2em;
						// padding: 10px 0;
						// color: #2c2c2c;
						// //font-weight:700;

						// @include break-point('at least', 'medium') {
						// 	font-size: 1.8rem;
						// }
					}

					.excerpt {
						// @include fonts__serif;
						color: #3f3f3f;
						font-size: 17px;
						font-weight: 300;
						line-height: 1.3em;
						margin-bottom: 20px;
						overflow: hidden;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						-moz-box-orient: vertical;

						p {

							margin: 0;
						}
					}


					.btn-readmore {
						@include button;
					}

					img {
						width: 100%;
					}
				}

				.loading-more-icon{

					@include fonts__sans-serif;

					svg{
						vertical-align: middle;
    					margin-left: 8px;
					}
					
				}
			}

			&__side {
				//background-color: #f9f9f9;
				width: 100%;
				padding-left: 24px;
				border-left: 1px solid #f0f0f0;
				@include break-point('at least', 'medium') {
					width: 33.4%;
				}
			}

		}

	}
}




.contact-section {
  position: relative;
  width: 100%;
  background: #e8e8e8;
  padding: 3rem 20px;
  text-align: center;

  button {
    @include button();
    display: inline-block;
    border-radius: 0;
    background-color: $color__orange;
    flex-basis: 40%;
    margin: 10px 0 0 2px;
    font-size: 13px;

    padding: 15px 50px;

    @include break-point('at least', 'medium') {
      font-size: 17px;
      flex-basis: 25%;

    }

    &:hover {
      background-color: $color__highlight;
    }
  }
}



.skills-section {
  position: relative;
  width: 100%;

  .content {
    background-color: #1a1a1a;
    max-width: 840px;
    margin: 0 auto;
    padding: 5rem 20px 1.5rem;

    h2,
    h1 {
      color: #e0e0e0;

      span {
        color: #888888;
        font-size: 14px;
        display: block;
        margin: 5px 0;

        @include break-point('at least', 'medium') {
          display: inline-block;
          margin: 0;
        }
      }
    }

    p {
      color: #e0e0e0;
    }

    ul {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-template-rows: repeat(10, auto);
      grid-auto-flow: column;
    }

    .list-icons {
      padding: 10px 0;
      display: flex;
      flex-wrap: wrap;

      .break {
        @include fonts__sans-serif;
        flex-basis: 100%;
          height: 30px;
          margin-bottom: 2rem;
          color: #e0e0e0;
      }

      @include break-point('at least', 'medium') {
        padding: 10px;
      }

      .react-tool-tip-box {
        h3 {
          font-size: 17px;
          margin-bottom: 5px;
        }

        p {
          font-size: 14px;
          color: #000;
          line-height: 1.5;
        }
      }

      .active-li {
        border-color: #f16334;
      }

      li {
        display: inline-block;
        height: auto;
        margin: 10px 2%;

        @include break-point('at least', 'medium') {
          flex-direction: row;
        }

        a {
          cursor: pointer;
        }



        .list-btn{

          @include button();
          width: 150px;
          padding: 0;
          display: flex;
          align-items: center;
          background: #1a1a1a;
          border: 1px solid #000;



          @media only screen and (max-width: 440px) {
            width: 145px;
          }

          @media only screen and (max-width: 320px) {
            width: 125px;
          }



          &:hover{
            border-top-left-radius: 18px;
            border-bottom-left-radius: 18px;
            
            border: 1px solid #f16334;
          }
  
  
          .skill-label {
            width: 100%;
            height: 35px;
            background: #2d2b2b;// rgb(42, 42, 42);
            border-radius: 5px;
            text-align: left;
            overflow: hidden;
            //box-shadow: inset -1px -1px 0px rgb(255 255 255 / 15%);
            border-top-left-radius: 18px;
            border-bottom-left-radius: 18px;
  
            @include break-point('at least', 'medium') {
              width: 100%;
              height: 35px;
            }
  
            span {
              display: inline-block;
              height: 35px;
              width: 0;
              vertical-align: top;
              //background: #237894;

              background: #616161;// #454141;
              // background: -moz-linear-gradient(90deg, rgba(65,192,255,1) 0%, rgba(29,178,253,1) 50%, rgba(252,176,69,1) 100%);
              // background: -webkit-linear-gradient(90deg, rgba(65,192,255,1) 0%, rgba(29,178,253,1) 50%, rgba(252,176,69,1) 100%);
              // background: linear-gradient(90deg, rgba(65,192,255,1) 0%, rgba(29,178,253,1) 50%, rgba(252,176,69,1) 100%);
              // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#41c0ff",endColorstr="#fcb045",GradientType=1);


              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
              //  transition: all 2s ease-out;

              border-right: 1px solid #616161;
              border-bottom: 1px solid #616161;


              figure{
                display: block;
                width: 35px;
                height: 35px;
                //border: 1px solid #c6c6c6;
                border-radius: 35px;
                font-size: 25px;
                text-align: center;
                margin: 0;
                background: #fff;
                line-height: 1.7em;
      
                img{
                  width: 35px;
                  padding: 1px;
                 // background-color: #616161;
                }
              }
      
            }
          }
         
        }

      }


      .custom-tooltip {

        @include fonts__sans-serif;
        font-weight: 300;
        max-width: 100%;

        width: 94%;
        margin: 0 3%;

        color: #000;
        font-size: 16px;
        font-weight: 300;

        background: #ffffff;
        line-height: 1.4;
        padding: 10px;

        
        &::after {
          border-bottom-color: #ffffff00;
        }

        @include break-point('at least', 'medium') {
          font-size: 14px;
          max-width: 300px;
          width: 100%;
          margin: 0;
          &::after {
            border-bottom-color: #ffffff;
          }
        }
      }
    }
  }
}




.projects-section {
  position: relative;
  width: 100%;
  padding: 0;
  max-width: $maxWidth;

  @include break-point('at least', 'medium') {
    padding: 0 20px;
  }

  .block {

    background: #fcfcfc;

    // &:nth-child(odd) {
    //   background: #f8f8f8;
    // }


    .project-block {
      display: flex;
      flex-direction: column;
      margin: 2.5rem 0;
      padding: 0;

      @include break-point('at least', 'medium') {
        flex-direction: row;

        padding: 2.5rem 20px;
      }


      .desc {
        flex-grow: 1;
        padding: 20px 0;
        border-bottom: 1px solid #ebebeb;

        .heart{
          width: 25px;
        }
  

        @include break-point('at least', 'medium') {
          padding: 10px 10px 10px 2rem;
          border-bottom: none;
        }

        p{
          @include fonts__sans-serif;
          font-size: 16px;
          line-height: 24px;
          margin: 0;
      
          @include break-point("at least", "medium") {
            font-size: 1.1rem;
          }
        }
  

        h2 {
          line-height: 34px;
          margin-bottom: 1rem;

        }


        h2{
          line-height: 34px;
          font-size: 1.7rem;
          margin-bottom: 1rem;
          @include break-point("at least", "medium") {
            font-size: 2rem;
          }
        }
      

        .wp-categories {

          ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              @include fonts__sans-serif;
              color: #f16334;
              text-transform: uppercase;
              font-size: 12px;
              margin-bottom: 0.5rem;
              display: inline-block;
            }
          }
        }

        .project-desc {
          display: block;
          height: 20px;
          overflow: hidden;

          h5 {
            @include fonts__sans-serif;
            font-size: 15px;
          }

          ul {
            li {
              @include fonts__sans-serif;
              font-size: 14px;
            }
          }
        }

        .cont{
          display: flex;
          flex-direction: column;



          .detail{
            transition: height 350ms ease;
             height: 0px;
            overflow: hidden;
            
            h5 {
              @include fonts__sans-serif;
              font-size: 15px;
              margin: 0 0 10px;
            }

            ul {
              li {
                @include fonts__sans-serif;
                font-size: 16px;
                line-height: 1.5;

              }
            }

         }

          .pd-text {
            display: inline-block;
            @include fonts__sans-serif;

            h5 {
              @include fonts__sans-serif;
              font-size: 15px;
              margin: 0 0 10px;
            }

            ul {
              li {
                @include fonts__sans-serif;
                font-size: 16px;
                line-height: 1.5;

              }
            }
          }

          button{
            margin: 10px 0;
            width: 111px;
            border-radius: 0;
            border: none;
            padding: 4px 5px;
            text-transform: uppercase;
            font-size: 11px;
            color: #505050;
            background-color: #cdcdcd;
          }
        }

        .btn-more-cont{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          border-radius: 0;
          border: none;
          padding: 4px 5px;
          margin-top: 10px;


          @include break-point('at least', 'medium') {
            justify-content: flex-start;
            margin-top: 20px;
          }
      
        }

        .btn-readmore {
          @include button;
          background: $healthPink;
          padding: 13px 22px;
          border-radius: 0;
		  border-radius: 21px;

		  &:hover{
			background: $healthPinkOver
		  }
        }

      }

      .desc-rev {
        padding: 20px 0;

        @include break-point('at least', 'medium') {
          padding: 0 2rem 0 10px;
        }
      }

      .image {
        flex-shrink: 0;

        @include break-point('at least', 'medium') {
          max-width: 600px;
          width: 60%;
          object-fit: contain;

        }

        // @media only screen and (max-width: 750px) {

        //   width:440px;
        // }

      }

      .vid {
        width: 100%;
        max-height: 400px;

        @include break-point('at least', 'medium') {
          width: 150%;
        }

        .vid-container {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: contain;
          padding-bottom: 56.25%;
        }

        .video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      // iframe{
      //   max-width: 600px;
      //   width: 100%;
      //     height: 255px;

      //   object-fit: contain;

      //   @include break-point('at least', 'medium') {
      //     height: 370px;
      //     width: 130%;
      //   } 

      // }
    }


    .pb-reversed {
      flex-direction: column;

      @include break-point('at least', 'medium') {
        flex-direction: row-reverse;
      }
    }
  }

}

.projects-collection {
  width: 100%;
  background-color: #000;
  padding: 2rem 0;

  .wrapper {
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    padding: 1rem;
    margin: 1.5rem auto;

    h1 {
      color: #fff;
      margin-bottom: 2rem;

      span {
        color: #888888;
        font-size: 14px;
        display: block;
        margin: 5px 0;

        @include break-point('at least', 'medium') {
          display: inline-block;
          margin: 0;
        }
      }
    }

  }

}



.html5-video-player:not(.ytp-transparent),
.html5-video-player.unstarted-mode,
.html5-video-player.ad-showing,
.html5-video-player.ended-mode,
.html5-video-player.ytp-fullscreen {
  background-color: #fff !important;
}

iframe {
  html {
    body {
      background-color: #fff;
    }
  }
}



.mobile-make-appt{
	position: relative;
    top: 70px;
    height: 31px;

	@include break-point('at least', 'medium') {
		height: 46px;
	}
}





/* CSS for desktop screens (1024px and above) */
@media (min-width: 768px) {
	.desktop-br {
	  display: block; /* Show the <br> element */
	}
  }
  
  /* CSS for smaller screens (below 1024px) */
  @media (max-width: 768px) {
	.desktop-br {
	  display: none; /* Hide the <br> element */
	}
  }




  .head-text-colored{
    color: $titleFontColor;
  }
  .reg-text-color{
    color: $regFontColor;
  }




  .header-tip-teal{
   @include fonts__sans-serif;
    color:#01ccfb !important;
    text-transform:uppercase;
    font-size:12px;
    font-weight: 700;
    letter-spacing: 2px;
  } 


  .header-tip{
    @include fonts__sans-serif;
    color:$healthPink !important;
    text-transform:uppercase;
    font-size:12px;
    font-weight: 700;
    letter-spacing: 2px;
  } 